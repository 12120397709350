import React, { useState } from "react"
import { styled } from "@mui/system"

import FacebookIcon from "../../static/svg/FacebookIcon.svg"
import MailIcon from "../../static/svg/MailIcon.svg"
import TwitterIcon from "../../static/svg/twitter.svg"
import LinkedIn from "../../static/svg/linkedin.svg"
import LinkIcon from "../../static/svg/ReadFullStoryIcon.svg"
import { Link } from "gatsby"
import { Typography } from "@mui/material"
import ToolTip from "../../static/svg/ToolTip.svg"

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width:576px)": {
    display: "flex",
    flexDirection: "row",
  },
})

const SocialContainer = styled(Link)({
  backgroundColor: "#0870E0",
  width: "58px",
  height: "58px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#0A5EB8",
  },
  "@media (max-width:576px)": {
    width: "48px",
    height: "48px",
  },
})

const CopyContainer = styled("button")({
    parring: 0, 
    border: "none",
    cursor: "pointer",
    backgroundColor: "#0870E0",
    width: "58px",
    height: "58px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.4,
    "&:hover": {
      backgroundColor: "#0A5EB8",
    },
    "@media (max-width:576px)": {
      width: "48px",
      height: "48px",
    },
  })

const FaceBookBox = styled(FacebookIcon)({
  width: "12,99px",
  height: "23px",
  "@media (max-width:576px)": {
    width: "10,75px",
    height: "19,03px",
  },
})

const MailIconBox = styled(MailIcon)({
  width: "24px",
  height: "15.63px",
  "@media (max-width:576px)": {
    width: "19,86px",
    height: "12,93px",
  },
})

const TwitterIconBox = styled(TwitterIcon)({
  width: "24.12px",
  height: "20.04px",
  "@media (max-width:576px)": {
    width: "19.96px",
    height: "16.58px",
  },
})

const LinkedInBox = styled(LinkedIn)({
  width: "20.1px",
  height: "20.9px",
  "@media (max-width:576px)": {
    width: "16.63px",
    height: "17.3px",
  },
})

const LinkIconBox = styled(LinkIcon)({
  width: "22px",
  height: "22.02px",
  "@media (max-width:576px)": {
    width: "18.21px",
    height: "18.22px",
  },
})

const TooltipWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity 0.3s",
  width: 58,
  height: 55,
  marginTop: 17,
  "@media (max-width:576px)": {
    width: 85,
    height: 30,
    flexDirection: "row",
    marginTop: 0,
    marginLeft: 17,
  },
})

const TooltipText = styled(Typography)({
  fontFamily: 'Heiti TC',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "10px",
  lineHeight: "150%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  letterSpacing: "-0.01em",
  textTransform: "uppercase",
  color: "#0870E0",
  marginRight: 0,
  "@media (max-width:576px)": {
    marginLeft: 11,
    textAlign: "left",
  },
})

export default function IconBox({ articleText }) {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const encodedText = encodeURI(articleText)
  const [copied, setCopied] = useState(false)

  const handleCopy = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(url)
    flashTooltip()

  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  
  async function flashTooltip() {
    setCopied(true)
    await delay(2000);
    setCopied(false)
  }


  return (
    <Wrapper>
      <SocialContainer
        href={`https://www.facebook.com/sharer.php?u=${url}`}
        style={{ opacity: 1 }}
        target="_blank" 
        rel="noopener noreferrer" 
      >
        <FaceBookBox />
      </SocialContainer>

      <SocialContainer
        href={`https://twitter.com/intent/tweet?url=${url}?text=${encodedText}`}
        style={{ opacity: 0.85 }}
        target="_blank" 
        rel="noopener noreferrer" 
      >
        <TwitterIconBox />
      </SocialContainer>
      <SocialContainer
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}}&title=${encodedText}`}
        style={{ opacity: 0.7 }}
        target="_blank" 
        rel="noopener noreferrer" 
      >
        <LinkedInBox />
      </SocialContainer>
      <SocialContainer href={`mailto:?subject=${encodedText}&body=Article:%20${url}`} 
        style={{ opacity: 0.55 }}
        target="_blank" 
        rel="noopener noreferrer" 
      >
        <MailIconBox />
      </SocialContainer>

      <CopyContainer onClick={(e) => {handleCopy(e)}}>
        <LinkIconBox />
      </CopyContainer>

      <TooltipWrapper style={{ opacity: copied ? 1 : 0}}>
        <div style={{width: 16, height: 16}}>
          <ToolTip />
        </div>
        <TooltipText>
          Link Copied
        </TooltipText>
      </TooltipWrapper>

    </Wrapper>
  )
}
