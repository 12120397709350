import React from "react"
import { graphql } from "gatsby"

import ArticleComponent from "../components/blogTemplate/articleComponent"
import Layout from "../components/layout"
import Seo from "../components/seo"



const BlogArticle = ({ data: { strapiNewsItem }}) => {
  const location = typeof window !== "undefined" ? window.location : ""
  const pageURL = location !== "" ? location.href : ""
  const imageURL = strapiNewsItem.image !== null ? strapiNewsItem.image.localFile.url : ""
  const metaData = [
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:site`,
      content: `@GrapheneVCF`,
    },
    {
      name: `twitter:title`,
      content: strapiNewsItem.title,
    },
    //TODO: CHANGE THE DESCRIPTION
    {
      name: `twitter:description`,
      content: strapiNewsItem.title,
    },
    {
      name: `twitter:image`,
      content: imageURL,
    },
    {
      name: `og:type`,
      content: `article`,
    },
    {
      name: `og:title`,
      content: strapiNewsItem.title,
    },
    {
      name: `og:description`,
      content: strapiNewsItem.title,
    },
    {
      name: `og:url`,
      content: pageURL,
    },
    {
      name: `og:image`,
      content: imageURL,
    },
  ]
    return(
        <Layout headerDark={false} firstScreen={false}>
        <Seo title={strapiNewsItem.title} meta={metaData}/>
        <ArticleComponent data={strapiNewsItem} />
    </Layout>
    )
}

export default BlogArticle


export const pageQuery = graphql`
  query BlogArticle($strapi_id: Int!) {
    strapiNewsItem(strapi_id: {eq: $strapi_id}) {
        image {
          localFile {
            publicURL
            url
            childImageSharp {
              gatsbyImageData
            }
          }
          alternativeText
        }
        content {
            data {
              content
            }
          }
        blog_type {
          Type
        }
        source_link
        source_name
        title
        strapi_id
        publication_date
      }
  }
`