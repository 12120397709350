import React from "react"
import ReactMarkdown from "react-markdown"
import { styled } from "@mui/system"

const MarkdownWrapper = styled(ReactMarkdown)({
  display: "flex",
  flexDirection: "column",
  // marginBottom: "60px",
  "@media (max-width:576px)": {
  },


  //Regular Text
  "& p": {
    fontFamily: "Merriweather",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "190%",
    color: "#222222",
    marginBottom: "29px",
    "@media (max-width:576px)": {
      fontSize: "16px",
      marginBottom: "18px",
    },

  },

  //Bullet List
  "& ul": {
    marginLeft: "35px",
    "@media (max-width:576px)": {
        marginLeft: "32px",
    },
    "& ::marker": {
      color: "#0870E0",
      fontSize: "18px",
      "@media (max-width:576px)": {
        fontSize: "16px",
      },
    },
  },

  //Numbered List
  "& ol": {
    marginLeft: "35px",
    "@media (max-width:576px)": {
        marginLeft: "32px",
    },
    "& ::marker": {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "190%",
      color: "#0870E0",
      "@media (max-width:576px)": {
        fontSize: "16px",
      },
    },
  },

  // Отступы от marker-ов
  "& li": {
    paddingLeft: "10px",
    "@media (max-width:576px)": {
      paddingLeft: "7px",
    },
    ":nth-last-of-type(1)": {
      marginBottom: "0px",
    }
  },

  //Bold Text
  "& strong": {
  },

  "& a": {
    // fontWeight: "400",
    // fontSize: "18px",
    textDecoration: "none",
    fontStyle: "normal",
    borderBottom: "1px solid #E3E3E3",
    color: "#0870E0",
  }
})
const StyledMarkdown = ({ content }) => {
  return <MarkdownWrapper children={content} />
}

export default StyledMarkdown
