import React from "react"
import { styled } from "@mui/system"

import StyledMarkdown from "./styledMarkdown"
import ShareIcon from "../../static/svg/ReadFullStoryIcon.svg"
import { Link } from "gatsby"
import { Typography } from "@mui/material"
import GridLines from "../gridLines"
import NoiceBack from "../../static/images/noize1.png"
import BackArrow from "../../static/svg/BackToBlogArrow.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import IconBox from "./iconBox"
import ArticleHeader from "./articleHeader"
import getTime from "../blog/timeConverter"

const Wrapper = styled("div")({
  position: "relative",
  display: "grid",
  margin: "0 auto",
  maxWidth: "1280px",
  gridTemplateColumns: "repeat(3, 1fr)",
  "@media (max-width:576px)": {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
  },
})

const TitleContainer = styled("div")({
  gridColumnStart: 2,
  gridColumnEnd: 4,
  gridRowStart: 1,
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  rowGap: "100px",
  pointerEvents: "none",
  marginTop: "36px",
  "@media (max-width:576px)": {
    marginTop: "31px",
  },
})

const ContentContainer = styled("div")({
  gridColumnStart: 2,
  gridColumnEnd: 4,
  gridRowStart: 3,
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginBottom: "106px",
  "@media (max-width:576px)": {
    marginBottom: "65px",
  },
})

const NavigationContainer = styled("div")({
  position: "sticky",
  top: 50,
  gridRowStart: 2,
  gridRowEnd: 4,
  gridColumnStart: 1,
  marginRight: 28,
  zIndex: 10,
  background: "white",
  display: "flex",
  maxHeight: 470,
  flexDirection: "column",
  // height: "1px",
    "@media (max-width:576px)": {
      position: "relative",
      top: 0,
      flexDirection: "column",
      gap: 0,
      marginBottom: "35px",
      marginRight: 0,
    },
})

const ButtonShare = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  border: "none",
  padding: 0,
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  width: "100%",
  height: "120px",
  borderRadius: "2px",
  gap: "12px",
  marginBottom: "19px",
  marginTop: "31px",
  "@media (max-width:576px)": {
    marginTop: "26px",
    height: "60px",
    gap: "8px",
    marginBottom: "15px",
  },
})

const NoiceBackground = styled("div")({
  backgroundImage: `url(${NoiceBack})`,
  position: "absolute",
  width: "100%",
  height: "100%",
})

const Text = styled(Typography)({
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#FFFFFF",
  letterSpacing: "-0,01em",
  lineHeight: "103%",
  fontSize: "28px",
  "@media (max-width:576px)": {
    fontSize: "18px",
  },
})

const StyledShareIcon = styled(ShareIcon)({
  marginBottom: "6px",
  "@media (max-width:576px)": {
    width: 17,
    heigth: 17,
    marginBottom: "3px",
  },
})

const BackToBlogContainer = styled("div")({
  gridRowStart: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "31px",
  marginRight: 28,
  "@media (max-width:576px)": {
    alignItems: "start",
    marginRight: 0,  

  },
})

const BackToBlogText = styled(Typography)({
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "32px",
  lineHeight: "135%",
  color: "#0870E0",
  "@media (max-width:576px)": {
    fontSize: "18px",
  },
})

const ImageContainer = styled(GatsbyImage)({
  marginBottom: "54px",
  "@media (max-width:576px)": {
    marginBottom: "19px",
  },
})

const BackArrowIcon = styled(BackArrow)({
  width: "14,23px",
  height: "28px",
  "@media (max-width:576px)": {
    width: "10,16px",
    height: "20px",
  },
})

const ShareText = styled(Typography)({
  marginBottom: "23px",
  marginTop: "10px",
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "22px",
  lineHeight: "104.5%",
  textAlign: "center",
  letterSpacing: "-0.01em",
  color: "#0870E0",
  "@media (max-width:576px)": {
    marginTop: 0,
    marginBottom: "8px",
    fontSize: "14px",
    textAlign: "left",
  },
})
const BackWrapper = styled(Link)({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "29px",
  "@media (max-width:576px)": {
    gap: "9px",
  },
})

const TimeNewsContainer = styled("div")({
  gridRowStart: 2,
  gridColumnStart: 2,
  gridColumnEnd: 4,
  "@media (max-width:576px)": {
    fontSize: "12px",
  },
})

const NewsFooter = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsBody,
  fontSize: "22px",
  lineGeight: "190%",
  color: theme.palette.secondary.main,
  opacity: 0.6,
  marginBottom: "23px",
  "& span": {
    fontWeight: 700,
  },
  "@media (max-width:1025px)": {
    fontSize: "0.67rem",
  },
  "@media (max-width:576px)": {
    fontSize: "12px",
    marginBottom: "16px",
  },
}))

const ArticleComponent = ({ data }) => {
  const shareLink = (data.source_link !== "/" && data.source_link) ?? false

  return (
    <>
      <Wrapper>
        <BackToBlogContainer>
          <BackWrapper to={`/blog/`}>
            <BackArrowIcon />
            <BackToBlogText>Back to blog</BackToBlogText>
          </BackWrapper>
        </BackToBlogContainer>
        <TitleContainer>
          <ArticleHeader item={data} />
        </TitleContainer>
        <TimeNewsContainer>
          <NewsFooter>
            {getTime(data.publication_date)}
            {data.source_name ? " - " : null}
            <span>{data.source_name}</span>
          </NewsFooter>
          {data.image !== null ? (
            <ImageContainer
              image={data.image?.localFile.childImageSharp.gatsbyImageData}
              alt={data.image?.alternativeText ?? `news-image`}
            />
          ) : null}
        </TimeNewsContainer>
        <NavigationContainer>
          <ShareText>SHARE THE STORY</ShareText>
          <IconBox articleText={data.title} />
        </NavigationContainer>
        <ContentContainer>
          {data.content ? <StyledMarkdown content={data.content.data.content} /> : null}
          {shareLink ? (
              <ButtonShare 
                href={data.source_link}
                target="_blank" 
                rel="noopener noreferrer" 
              >
                <Text>READ THE FULL STORY</Text>
                <StyledShareIcon />
                <NoiceBackground />
              </ButtonShare>
          ) : null}
        </ContentContainer>
      </Wrapper>
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 0,
          marginTopMob: 0,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "#F8F8F8",
        }}
      />
    </>
  )
}

export default ArticleComponent
