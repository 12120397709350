import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"

const NewsItem = styled("div")({
  display: "flex",
  flexDirection: "column",
  transition: "all 0.4s",
  "&:hover": {
    "p:first-of-type": {
      color: "#0870E0",
    },
    "& img": {
      filter: "grayscale(1)",
    },
    "& #blue-background": {
      opacity: 0.22,
    },
  },
})

const NewsType = styled("div")(({ theme }) => ({
  cursor: "default",
  display: "flex",
  justifyContent: "flex-start",
  marginRight: "inherit",
  marginBottom: "47px",
  "& hr": {
    color: theme.palette.secondary.blue,
    width: "100%",
    height: "4px",
    backgroundColor: theme.palette.secondary.blue,
    margin: "0.8em auto",
  },
  "@media (max-width:576px)": {
    marginRight: 0,
    marginBottom: "19px",
    "& hr": {
      width: "100%",
      height: "3px",
    },
  },
}))

const NewsHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsTitle,
  color: theme.palette.secondary.blue,
  whiteSpace: "nowrap",
  fontSize: "32px",
  marginRight: "19px",
  textTransform: "uppercase",
  "@media (max-width:1025px)": {
    fontSize: "1rem",
  },
  "@media (max-width:576px)": {
    marginRight: "17px",
    fontSize: "18px",
  },
}))

const NewsTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.newsBody,
  fontSize: "36px",
  marginBottom: "16px",
  transition: "all 0.4s",
  "@media (max-width:576px)": {
    fontSize: "18px",
    marginBottom: "20px",
  },
}))

const ArticleHeader = ({ item }) => {
  return (
    <NewsItem>
      <NewsType>
        <NewsHeader>{item.blog_type.Type}</NewsHeader>
        <hr />
      </NewsType>
      <NewsTitle>{item.title}</NewsTitle>
    </NewsItem>
  )
}

export default ArticleHeader
